<template>
    <div class="radio">
            <input :id="id" :name="name" type="radio" :checked="isChecked" @click="clickHandler">
            <label :for="id" class="radio-label text-preset_default-body">{{ label }}</label>
    </div>
</template>

<script>
export default {
  name: 'formTextinput',
  props: {
    id: String,
    name: String,
    label: String,
    selected: String
  },
  data() {
    return {
    }
  },
  methods: {
    clickHandler(e) {
      this.$bus.$emit('update-time-zone', this.id)
    }
  },
  computed: {
    isChecked() {
      return this.id === this.selected;
    }
  }
}
</script>

<style scoped>
.radio {
  margin: 0.5rem;
}

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio input[type="radio"] + .radio-label:before {
  content: '';
  background: #fff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #3197EE;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #3197EE;
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
</style>