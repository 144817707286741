<template>
    <div class="radio-container">
        <label class="text-preset_default-body">Time zone</label>
        <field-alert
          :errorMessage="errorMessage"
          v-if="errorMessage.length"
        >
        </field-alert>
        <radio-button
            v-for="(option, index) in options"
            :id="option.id"
            :name="option.name"
            :label="option.label"
            :selected="selectedTimezone"
        ></radio-button>
    </div>
</template>

<script>
import RadioButton from './RadioButton.vue';
import FieldAlert from '@/components/alerts/FieldAlert.vue';

export default {
  name: 'formTextinput',
  components: {
    RadioButton,
    FieldAlert
  },
  props: {
    userTimezone: String
  },
  data() {
    return {
      errorMessage: '',
      defaultTimeZone: 'CT',
      selectedTimezone: '',
      options: [
          {
              id: 'ET',
              name: 'timezone',
              label: 'Eastern Time'
          },
          {
              id: 'CT',
              name: 'timezone',
              label: 'Central Time'
          },
          {
              id: 'MT',
              name: 'timezone',
              label: 'Mountain Time'
          },
          {
              id: 'PT',
              name: 'timezone',
              label: 'Pacific Time'
          }
      ]
    }
  },
  beforeMount() {
    if (!this.userTimezone || this.userTimezone.length < 1) {
      const currentTimezone = this.getCurrentTimezone();

      this.$bus.$emit('update-time-zone', currentTimezone);
      this.selectedTimezone = currentTimezone;
    } else {
      this.selectedTimezone = this.userTimezone;
    }

    this.subscribeToEvents();
  },
  methods: {
    getCurrentTimezone() {
      const offset = new Date().getTimezoneOffset();
      let tz = '';

      switch (offset) {
        case 300:
          tz = 'ET'
          break;
        case 360:
          tz = 'CT'
          break;
        case 420:
          tz = 'MT'
          break;
        case 480:
          tz = 'PT'
          break;
      
        default:
          tz = this.defaultTimeZone;
          break;
      }

      return tz;
    },

    subscribeToEvents() {
      this.$bus.$on('form-error-timezone', message => {
        this.errorMessage = message;
      });

      this.$bus.$on('update-time-zone', id => {
        this.errorMessage = '';
      })
    }
  }
}
</script>

<style scoped>
.radio-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
</style>