<template>
    <div class="content-container">
        <br>
            <h1 class="text-preset_page-heading">Successfully unsubscribed</h1>
        <br>

        <p class="text-preset_default-body">
          You have been successfully unsubscribed from Care Management marketing emails.
        </p>
    </div>
</template>

<script>

export default {
  name: 'unsubscribeView',
  mounted() {
    document.getElementsByTagName('title')[0].innerText = 'Care Management Services';
  }
}
</script>

<style scoped>
.content-container {
  margin: 0 auto;
  width: 95%;

  @media (max-width: 767px;) {
    width: 100%;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--interaction-color-default);
}
</style>