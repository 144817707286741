<template>
    <field-alert
        v-if="errorMessage.length"
        :errorMessage="errorMessage"
    ></field-alert>
    <div class="selector-container">
        <slot-group 
            v-for="(row, index) in slotData.rows" 
            :key="index"
            :name="row.name"
            :slots="row.slots"
            :rowIndex="index"
        ></slot-group>
    </div>
</template>

<script>
import SlotGroup from './SlotGroup.vue';
import FieldAlert from '@/components/alerts/FieldAlert.vue';

export default {
  name: 'formSlotselector',
  props: {
    slotData: Object
  },
  components: {
    SlotGroup,
    FieldAlert
  },
  data() {
    return {
      errorMessage: ''
    }
  },
  mounted() {
    this.subscribeToEvents();
  },

  methods: {
    subscribeToEvents() {
      this.$bus.$on('form-error-slots', message => {
        this.errorMessage = message;
      });

      this.$bus.$on('toggle-time-slot', ({rowIndex, slotIndex}) => {
        this.errorMessage = '';
      });
    }
  }
}
</script>

<style scoped>
.selector-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
</style>