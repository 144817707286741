<template>
    <div class="content-container">
      <br>
      <h1 class="text-preset_page-heading">Welcome!</h1>
      <br>

      <p class="text-preset_default-body">
          Our patient engagement team will be happy to tell you more about Care Management Services, 
          answer any questions you may have, and schedule you for your first care management appointment. 
          Let us know how and when you'd like us to reach out. In the meantime, you can visit the <a href="https://go.cms.gov/ccm">CMS website</a> to learn more.
      </p>
      <br>

      <form-container
        :userData="userData"
        :formErrors="formErrors"
      ></form-container>
    </div>
</template>

<script>
import FormContainer from '@/components/form/FormContainer.vue';

export default {
  name: 'mainView',
  props: {
    userData: Object,
    formErrors: Array
  },
  components: {
    FormContainer
  }
}
</script>

<style scoped>
.content-container {
  margin: 0 auto;
  width: 95%;

  @media (max-width: 767px;) {
    width: 100%;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--interaction-color-default);
}
</style>