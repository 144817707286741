<template>
    <div class="alert-container" ref="global__alert">
        <div class="heading-container">
            <p class="exclamation-point">!</p>
            <p class="text-preset_default-body_semibold">Please fix the following errors.</p>
        </div>
        <div class="errors-list-container">
            <ul class="errors-list">
                <li class="text-preset_default-body"
                    v-for="(error, idx) in formErrors"
                    :key="idx"
                >&#x2022; {{ error.message }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'globalAlert',
    props: {
        formErrors: Array
    },
    mounted() {
        this.$refs["global__alert"].scrollIntoView({ behavior: 'smooth' });
    }
}
</script>

<style scoped>
.alert-container {
    border-left: 6px solid var(--alert-color-attention);
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: relative;
}

.heading-container {
    display: flex;
    align-items: center;
}

.exclamation-point {
    background-color: var(--alert-color-attention);
    font-weight: bolder;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    vertical-align: middle;
}

.errors-list-container {
    padding-left: 10%;
}

li {
    list-style-type: none;
}
</style>