<template>
    <div class="alert-container text-preset_default-body">
        <p class="exclamation-point">!</p>
        <p>{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
    name: 'fieldAlert',
    props: {
        errorMessage: String
    }
}
</script>

<style scoped>
.alert-container {
    border-left: 6px solid var(--alert-color-attention);
    padding: 10px;
    max-width: fit-content;
    margin-top: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
}

.exclamation-point {
    background-color: var(--alert-color-attention);
    font-weight: bolder;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    vertical-align: middle;
}
</style>