<template>
    <div class="button-container">
        <button 
            class="text-preset_sub-section-heading button_submit-form"
            @click.prevent="clickHandler"
            :disabled="isDisabled"
        >Request Call</button>
    </div>
</template>

<script>
export default {
    props: {
        isDisabled: Boolean
    },
    methods: {
        clickHandler() {
            this.$bus.$emit('submit-callback')
        }
    }
}
</script>

<style scoped>
.button-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.button_submit-form {
    height: 50px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;

    background-color: var(--interaction-color-default);
    color: var(--font-color-inverted);
}

.button_submit-form:active {
    background-color: var(--interaction-color-select);
}

.button_submit-form:disabled, 
.button_submit-form:disabled:hover {
    background-color: var(--interaction-color-disabled);
    cursor: not-allowed;
}

.button_submit-form:hover {
    background-color: var(--interaction-color-hover);
}

.button_submit-form:focus {
    background-color: var(--interaction-color-focus);
}
</style>