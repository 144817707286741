export default class Logger {
    extendedLoggingEnabled = false;

    constructor(enableLogging) {
        this.extendedLoggingEnabled = enableLogging;
    }

    success(message, data) {
        if (!this.extendedLoggingEnabled) return;

        console.log(`%c${message} ${data?':':''} ${data?JSON.stringify(data, null, 4):''}`, 'color: lime; background-color: black;');
    }

    error(message, data) {
        if (!this.extendedLoggingEnabled) return;

        console.log(`%c${message} ${data?':':''} ${data?JSON.stringify(data, null, 4):''}`, 'color: red; background-color: black;');
    }

    inform(message, data) {
        if (!this.extendedLoggingEnabled) return;
        
        console.log(`%c${message} ${data?':':''} ${data?JSON.stringify(data, null, 4):''}`, 'color: yellow; background-color: black;');
    }
}