import { createApp } from 'vue'
import App from './App.vue'

import Logger from './utils/logger'
import $bus from './utils/evtBus'
import './assets/styles.css'
const app = createApp(App)

const log = new Logger(false); // Boolean that enables logging

app.config.globalProperties.$bus = $bus;
app.config.globalProperties.log = log;
app.config.globalProperties.flowBaseUrl = 'https://em.athena-east1.api.onereach.ai/http/41149479-7f8e-48f2-aaaf-e8d083836f5a/campaign/v1/landing';
app.config.globalProperties.unsubscribeUrl = 'https://em.athena-east1.api.onereach.ai/http/41149479-7f8e-48f2-aaaf-e8d083836f5a/campaign/v1/actions-listener';

app.mount('#app')
