<template>
    <div class="content-container">
      <br>
      <h1 class="text-preset_page-heading">Talk to you soon</h1>
      <br>

      <p class="text-preset_default-body">
        Our patient engagement team will reach out at your preferred day and time. 
        In the meantime, you can visit the <a href="https://go.cms.gov/ccm">CMS website</a> to learn more.
      </p>

      <div class="data-container">
        <div class="data-sub_container">
          <p class="text-preset_default-body">Preferred phone number</p>
          <p class="text-preset_default-body_semibold">{{ formatNumber(userData.prefferedNumber) }}</p>
        </div>

        <div class="data-sub_container">
          <p class="text-preset_default-body">Preferred days and times</p>
          <div
            v-if="displaySlots.length > 0"
            v-for="(row, ridx) in displaySlots"
            :key="ridx"
          >
            <p class="text-preset_default-body_semibold">
              {{ row.name }}: <span>{{ row.slots.join(', ') }}</span>
            </p>
          </div>
          <div v-else>
            <p class="text-preset_default-body_semibold">All</p>
          </div>
        </div>

        <div class="data-sub_container">
          <p class="text-preset_default-body">Time Zone</p>
          <p class="text-preset_default-body_semibold">{{ formatZone(userData.timezone) }}</p>
        </div>

        <button 
          v-if="!userData.isPolled"
          class="text-preset_default-body edit-button"
          @click.prevent="clickHandler"
        >Edit</button>
      </div>
    </div>
</template>

<script>
export default {
  name: 'editView',
  props: {
    userData: Object,
    allowReturn: Boolean
  },
  computed: {
    displaySlots() {
      return this.userData.slotData.rows.map(row => {
        return {
          name: row.name,
          slots: row.slots.map(s => {
            return s.isSelected ? s.label : undefined;
          }).filter(s => s !== undefined)
        }
      }).filter(r => r.slots.length !== 0);
    }
  },
  methods: {
    formatNumber(number) {
        let num = number.replace(/[^\dX]/g, '')
        let result = '';

        for (let i = 0; i < num.length && i < 10; i++) {
            switch (i) {
            case 0:
                result += `(${num[i]}`;
                continue;
            case 3:
                result += ") ";
                break;
            case 6:
                result += "-";
                break;
            default:
                break;
            }
            result += num[i];
        }

        return result;
    },

    formatZone(tz) {
      let outputTimezone ='';
      switch (tz) {
        case 'ET':
        outputTimezone = 'Eastern Time';
          break;
        case 'CT':
        outputTimezone = 'Central Time';
          break;
        case 'MT':
        outputTimezone = 'Mountain Time';
          break;
        case 'PT':
        outputTimezone = 'Pacific Time';
          break;
      
        default:
        outputTimezone = 'Central Time';
          break;
      }
      return outputTimezone;
    },

    clickHandler(e) {
      this.$bus.$emit('set-view', 'main');
    }
  }
}
</script>

<style scoped>
.content-container {
  margin: 0 auto;
  width: 95%;

  @media (max-width: 767px;) {
    width: 100%;
  }
}

.data-container {
  width: 350px;

  @media (max-width: 767px;) {
    width: 100%;
  }
}

.data-sub_container {
  margin-top: 25px;
}

.edit-button {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid var(--border-color-default);
  border-radius: 3px;
  margin-top: 25px;
  padding: 10px;
}

.edit-button:active {
  border: 1px solid var(--border-color-dark);
}

.edit-button:hover {
  border: 1px solid var(--border-color-light);
}

.edit-button:focus {
  border: 1px solid var(--border-color-light);
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--interaction-color-default);
}
</style>