<template>
    <button 
        class="button_time-slot text-preset_small-body"
        :class="{
            'button_time-slot_selected': isSelected
        }"
        @click.prevent="onClickHandler"
    >{{ slotName }}</button>
</template>

<script>
export default {
  name: 'slotButton',
  props: {
    slotName: String,
    isSelected: Boolean,
    rowIndex: Number,
    slotIndex: Number
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    onClickHandler() {
        this.$bus.$emit('toggle-time-slot', {rowIndex: this.rowIndex, slotIndex: this.slotIndex});
    }
  }
}
</script>

<style scoped>
.button_time-slot {
    height: 50px;
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: var(--bg-color-default);
    color: var(--font-color-default);
    border: 1px solid var(--border-color-default);

    border-radius: 5px;
    cursor: pointer; 
}

.button_time-slot_selected {
    background-color: var(--interaction-color-focus);
    color: var(--interaction-color-hover-dark);
    border: 1px solid var(--interaction-color-dark);
    font-weight: 600;
}
</style>