<template>
    <div class="form-container">
        <global-alert
          v-if="formErrors.length"
          :formErrors="formErrors"
        >
        </global-alert>
        
        <form>
            <p class="text-preset_sub-section-heading">Confirm contact information</p>
            <form-textinput
              :modelValue="userData.prefferedNumber"
            ></form-textinput>

            <p class="text-preset_sub-section-heading">Select preferred days and times</p>
            <form-slotselector
                :slotData="userData.slotData"
            ></form-slotselector>

            <form-radiogroup
              :userTimezone="userData.timezone"
            ></form-radiogroup>

            <submit-button
              :isDisabled="this.formErrors.length > 0"
            ></submit-button>
        </form>

    </div>
</template>

<script>
import FormTextinput from './FormTextinput.vue';
import FormSlotselector from './slotselector/FormSlotselector.vue';
import FormRadiogroup from './radio/RadioGroup.vue';
import SubmitButton from './SubmitButton.vue';
import GlobalAlert from '../alerts/GlobalAlert.vue';

export default {
  name: 'formContainer',
  props: {
    userData: Object,
    formErrors: Array
  },
  components: {
    FormTextinput,
    FormSlotselector,
    FormRadiogroup,
    SubmitButton,
    GlobalAlert
  }
}
</script>

<style scoped>
p {
    margin-bottom: 5px;
}
.form-container {
    width: 350px;

    @media (max-width: 767px) {
      width: 100%;
    }
}

.g-recaptcha {
  margin-bottom: 10px;
}
</style>