<template>
    <div class="group">
        <label class="text-preset_default-body">{{ name }}</label>
        <div class="sub-group">
            <slot-button 
                v-for="(slot, index) in slots" 
                :key="index"
                :slotName="slot.label"
                :isSelected="slot.isSelected"
                :rowIndex="rowIndex"
                :slotIndex="index"
            ></slot-button>
        </div>
    </div>
</template>

<script>
import SlotButton from './SlotButton.vue';

export default {
  name: 'slotGroup',
  components: {
    SlotButton
  },
  props: {
    name: String,
    slots: Array,
    rowIndex: Number
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.sub-group {
    display: flex;
    gap: 10px;
    width: 100%;
    margin-top: 5px;
}
</style>