<template>
    <div class="input-container">
        <label class="text-preset_default-body">Preferred phone number</label>
        <input 
            class="input-field"
            :class="{
              invalid__value: errorMessage.length
            }"
            type="text"
            v-model="phoneNumber"
            @input="inputHandler"
            v-mask="'(___) ___-____'"
        >

        <field-alert
            v-if="errorMessage.length"
            :errorMessage="errorMessage"
        ></field-alert>
    </div>
</template>

<script>
import FieldAlert from '../alerts/FieldAlert.vue';

export default {
  name: 'formTextinput',
  props: {
    modelValue: String
  },
  components: {
    FieldAlert
  },
  data() {
    return {
        phoneNumber: '',
        isInitial: false,
        errorMessage: ''
    }
  },
  mounted() {
    this.subscribeToEvents();

    this.phoneNumber = this.formatNumber(this.modelValue);
    this.isInitial = true;
  },
  methods: {
    inputHandler(e) {
        if (this.isInitial) {
            this.phoneNumber = '';
            this.isInitial = false;
        }

        this.errorMessage = '';

        this.$bus.$emit('update-phone-number', this.phoneNumber.replace(/[^0-9]/gm, '').slice(0, 10));
    },

    formatNumber(number) {
        let num = number.replace(/[^\dX]/g, '')
        let result = '';

        for (let i = 0; i < num.length && i < 10; i++) {
            switch (i) {
            case 0:
                result += `(${num[i]}`;
                continue;
            case 3:
                result += ") ";
                break;
            case 6:
                result += "-";
                break;
            default:
                break;
            }
            result += num[i];
        }

        return result;
    },

    subscribeToEvents() {
        this.$bus.$on('form-error-input-number', (message) => {
            this.errorMessage = message
        });
    }
  },
  watch: {
    phoneNumber(newValue, oldValue) {
      this.log.inform(`Phone nuber change: ${oldValue} - ${newValue}`)
    }
  },
  directives: {
    mask: {
        mounted: function (el, binding) {
        var mask = binding.value,
            first = mask.indexOf('_'),
            fieldsL = mask.replace(/[^_]/gm, '').length,
            clean = mask.replace(/[^0-9_]/gm, ''),
            indexes = []
            
        for(var i = 0; i < clean.length; i++){
          if(!isNaN(clean[i])){
            indexes.push(i)
          }
        }
        
        binding.instance.phoneNumber = mask
        el.clean = mask.replace(/[^0-9]/gm, '')
        
        function maskIt(event, start){
          var value = binding.instance.phoneNumber,
              filtred = value.replace(/[^0-9]/gm, ''),
              result = ''
          
          if(value.length < first){
            value = mask + value
            filtred = value.replace(/[^0-9]/gm, '')
          }
          
          for(var i = 0; i < filtred.length; i++){
            if(indexes.indexOf(i) == -1){
              result += filtred[i]
            }
          }
          
          value = ''
          var cursor = 0
          
          for(var i = 0; i < mask.length; i++){
            if(mask[i] == '_' && result){
              value += result[0]
              result = result.slice(1)
              cursor = i + 1

            }else{
              value += mask[i]
            }
          }
    
          if(cursor < first){
            cursor = first
          }
          
          el.value = value
          binding.instance.phoneNumber = value;
          
          el.setSelectionRange(cursor,cursor)
        }
        
        el.addEventListener('focus', function(event){
          event.preventDefault()
        })
        
        el.addEventListener('click', function(event){
          event.preventDefault()
          var start = el.value.indexOf('_')
          
          if(start == -1){
            start = el.value.length
          }
          
          el.setSelectionRange(start,start)
          
        })
        
        el.addEventListener('paste', function(event){
          var start = el.selectionStart
          
          if(start < first){
            el.value = '_' + el.value
          }
        })
        
        el.addEventListener('input', function(event){
          var start = el.selectionStart      
          maskIt(event, start)
        })
        
      }
    }
  }
}
</script>

<style scoped>
.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input-field {
    height: 30px;
    padding: 5px 10px;
    border: 1px solid var(--border-color-default);
    border-radius: 5px;
}

.invalid__value {
  border: 1px solid var(--alert-color-attention);
}
</style>