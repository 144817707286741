<template>
  <global-header></global-header>

  <loader-view v-if="isLoading"></loader-view>
  <error-view 
    v-if="isError"
    :errorData="{}"
  ></error-view>
  <main-view 
    v-if="isMainView"
    :userData="userData"
    :formErrors="formErrors"
  ></main-view>
  <edit-view 
    v-if="isEditView"
    :userData="userData"
  ></edit-view>
  <unsubscribe-view
    v-if="isUnsubscribeView"
  >
  </unsubscribe-view>

  <global-footer></global-footer>
</template>

<script>
import LoaderView from './views/LoaderView.vue'
import ErrorView from './views/ErrorView.vue'
import MainView from './views/MainView.vue'
import EditView from './views/EditView.vue'
import UnsubscribeView from './views/UnsubscribeView'

import GlobalHeader from './components/global/GlobalHeader.vue'
import GlobalFooter from './components/global/GlobalFooter.vue'


export default {
  name: 'App',
  components: {
    LoaderView,
    ErrorView,
    MainView,
    EditView,
    UnsubscribeView,

    GlobalHeader,
    GlobalFooter
  },
  data() {
    return {
      pid: undefined,
      source: undefined,
      userData: null,

      isLoading: false,
      isError: false,
      isMainView: false,
      isEditView: false,
      isUnsubscribeView: false,

      formErrors: []
    }
  },
  async created() {
    this.setPageState('loading');

    const params = this.getUrlParams();

    if (params.get('pid')) return this.setPageState('error');

    this.p = params.get('p');
    this.s = params.get('s');
    const isUnsubscribeRequest = params.get('u') === 't';

    if (!this.s || !this.p) return this.setPageState('error');

    if (isUnsubscribeRequest) return this.unsubscribe();

    this.getPatientData();

    this.subscribeToFormEvents();
    this.subscribeToViewEvents();
  },
  methods: {
    getUrlParams() {
      return new URLSearchParams(window.location.search);
    },

    setPageState(nextState) {
      switch (nextState) {
        case 'loading':
          this.isLoading = true;
          this.isError = false;
          this.isEditView = false;
          this.isMainView = false;
          break;
        case 'error':
          this.isLoading = false;
          this.isError = true;
          this.isEditView = false;
          this.isMainView = false;
          break;
        case 'main':
          this.isLoading = false;
          this.isError = false;
          this.isEditView = false;
          this.isMainView = true;
          break;
        case 'edit':
          this.isLoading = false;
          this.isError = false;
          this.isEditView = true;
          this.isMainView = false;
          break;
        case 'unsubscribe':
          this.isLoading = false;
          this.isError = false;
          this.isEditView = false;
          this.isMainView = false;
          this.isUnsubscribeView = true;
          break;
      
        default:
          this.isLoading = false;
          this.isError = true;
          this.isEditView = false;
          this.isMainView = false;
          break;
      }
    },

    async unsubscribe() {
      const isUnsubscribeSuccessfull = await this.unsubscribePatient();

      if (isUnsubscribeSuccessfull) return this.setPageState('unsubscribe');
      
      this.setPageState('error');
    },

    async unsubscribePatient() {
      const url = this.createRequestUrl(this.unsubscribeUrl, [
        {
          name: 'p',
          value: this.p
        },
        {
          name: 'a',
          value: 'u'
        },
        {
          name: 's',
          value: this.s
        }
      ]);

      try {
        const resp = await fetch(url);
        const data = await resp.json();

        return data.isSuccess;
      } catch (error) {
        return false;
      }
    },

    async getPatientData() {
      const uri = this.createRequestUrl(this.flowBaseUrl, [
        {
          name: 'p',
          value: this.p
        },
        {
          name: 's',
          value: this.s
        }
      ]);

      try {
        const response = await fetch(uri);
        if (response.status !== 200) throw new Error();
        
        const data = await response.json();
        this.userData = data;

        if (data.isSubmitted) this.setPageState('edit');
        else this.setPageState('main');
      } catch (error) {
        this.setPageState('error');
      }
    },

    async updatePatientCallback() {
      this.setPageState('loading');
      
      const uri = this.createRequestUrl(this.flowBaseUrl, []);

      const options = {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            ...this.userData,
            s: this.s,
            p: this.p
          })
        };

      try {
        const response = await fetch(uri, options);
        if (response.status !== 200) throw new Error();

        const data = await response.json();

        if (!data.isSuccess || data.isError) throw new Error();

        this.setPageState('edit');
      } catch (error) {
        this.setPageState('error');
      }
    },

    subscribeToFormEvents() {
      this.$bus.$on('toggle-time-slot', ({rowIndex, slotIndex}) => {
          if(typeof rowIndex !== 'number' || typeof slotIndex !== 'number') return;

          const slotRow = this.userData.slotData.rows[rowIndex];
          const slot = slotRow.slots[slotIndex];

          slot.isSelected = !slot.isSelected;

          this.formErrors = this.formErrors.filter(e => e.type !== 'slots');
      });

      this.$bus.$on('update-phone-number', (newValue) => {
        this.userData.prefferedNumber = newValue;

        this.formErrors = this.formErrors.filter(e => e.type !== 'phone');
      });

      this.$bus.$on('update-time-zone', (newValue) => {
        this.userData.timezone = newValue;

        this.formErrors = this.formErrors.filter(e => e.type !== 'timezone');
      });

      this.$bus.$on('submit-callback', () => {
        const isValid = this.validateFormData();
        if (!isValid || this.formErrors.length > 0) return;

        this.updatePatientCallback();
      });
    },

    subscribeToViewEvents() {
      this.$bus.$on('set-view', (view) => {
        if (view === 'main') this.setPageState('main');
      })
    },

    createRequestUrl(uri, args) {
      const u = new URL(uri)

      args.forEach(arg => {
        u.searchParams.append(arg.name, arg.value);
      });

      return u.toString();
    },

    validateFormData() {
      let isError = false;
      
      if (!this.isValidNumber()) {
        if (!this.formErrors.some(e => e.type === 'phone')) this.formErrors.push({
          message: 'Phone number is required',
          type: 'phone'
        });
        this.$bus.$emit('form-error-input-number', 'Phone number is required');
        isError = true;
      }

      if (!this.isValidSlots()) {
        if (!this.formErrors.some(e => e.type === 'slots')) this.formErrors.push({
          message: 'At least one slot has to be selected',
          type: 'slots'
        });
        this.$bus.$emit('form-error-slots', 'At least one slot has to be selected');
        isError = true;
      }

      if (!this.isValidTimeZone()) {
        if (!this.formErrors.some(e => e.type === 'timezone')) this.formErrors.push({
          message: 'Time zone is required',
          type: 'timezone'
        });
        this.$bus.$emit('form-error-timezone', 'Time zone is required');
        isError = true;
      }

      return !isError;
    },

    isValidNumber() {
      const number = this.userData.prefferedNumber;

      return number.replace(/[^\dX]/g, '').length === 10;
    },

    isValidSlots() {
      const slots = this.userData.slotData;

      return slots.rows.some(r => r.slots.some(s => s.isSelected));
    },

    isValidTimeZone() {
      const timezone = this.userData.timezone;

      return ['ET', 'CT', 'MT', 'PT'].includes(timezone);
    }
  },
  mounted() {
    const linkSerif = document.createElement('link');
    linkSerif.rel = 'stylesheet';
    linkSerif.href = 'https://fonts.googleapis.com/css?family=Source+Serif+Pro:100,200,300,400,500,600,700,800';
    document.getElementsByTagName('head')[0].appendChild(linkSerif);

    const linkSans = document.createElement('link');
    linkSans.rel = 'stylesheet';
    linkSans.href = 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700,800';
    document.getElementsByTagName('head')[0].appendChild(linkSans); 
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
