<template>
    <footer>
        <p class="text-preset_legal-copy">powered by</p>
        <img class="powered-by_logo" src="https://acrmc.com/wp-content/uploads/2022/02/athena-up.png" alt="Powered by athenahealth" />
    </footer>
</template>

<script>
export default {
  name: 'globalFeader'
}
</script>

<style scoped>
footer {
    margin-top: auto;
    text-align: center;
    padding: 5px 10px;
    border-top: 1px solid var(--border-color-default);
}

img.powered-by_logo {
    max-width: 100px;
    max-height: 50px;
}
</style>