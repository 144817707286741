<template>
    <header>
        <p class="text-preset_sub-section-heading">Care Management Services</p>
    </header>
</template>

<script>
export default {
  name: 'globalHeader'
}
</script>

<style scoped>
header {
    padding: 5px 30px;
    border-bottom: 1px solid var(--border-color-default);

    @media (max-width: 767px) {
        padding: 5px 10px;
    }
}
</style>